import React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import { NotFoundQuery } from '../../types/graphql-types'
import Content from '../components/Content'
import Header from '../components/Header'
import Image404 from '../assets/page_not_found.svg'

interface Props {
  intl: IntlShape
  data: NotFoundQuery
}

const NotFoundPage: React.FC<Props> = ({ data }: Props) => {
  const intl = useIntl()

  const siteUrl = data.site!.siteMetadata!.siteUrl!

  return (
    <Layout
      menuData={(data as any).cms.mainMenu[0]}
      footerMenuData={(data as any).cms.footerMenus}
      languageSwitchData={(data as any).cms.languages}
    >
      <SEO
        title={intl.formatMessage({ id: 'notfound.header' })}
        link={[
          {
            rel: 'canonical',
            href: `${siteUrl}/${intl.locale}/404`,
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: `${siteUrl}/en/404`,
          },
          {
            rel: 'alternate',
            hrefLang: 'de',
            href: `${siteUrl}/de/404`,
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: `${siteUrl}/en/404.html`,
          },
          {
            rel: 'alternate',
            hrefLang: 'de',
            href: `${siteUrl}/de/404.html`,
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: `${siteUrl}/de/404`,
          },
        ]}
      />
      <Content>
        <Header style={{ marginBottom: 0 }}>
          <FormattedMessage id="notfound.header" />
        </Header>
        <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
          <FormattedMessage id="notfound.description" />
        </p>
        <Image404 />
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query NotFound($language: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cms {
      mainMenu: menus(
        where: { Position: "Main", Language: { locale: $language } }
      ) {
        ...MainMenuFragment
      }
      footerMenus: menus(
        where: { Position_ne: "Main", Language: { locale: $language } }
      ) {
        ...FooterMenusFragment
      }

      languages {
        ...LanguageSwitchFragment
      }
    }
  }
`

export default NotFoundPage
